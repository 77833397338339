import Table, { TableHeader, TableBody } from '@ingka/table';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import useData from 'hooks/useData';
import useLoading from 'hooks/useLoading';
import { useTranslation } from 'react-i18next';
import React, { useState, useCallback } from 'react';
import Text from '@ingka/text';
import { useLocation } from 'react-router-dom';
import { OrgDepartment } from 'types/dataContext';
import Search from '@ingka/search';
import InlineMessage from '@ingka/inline-message';
import information from '@ingka/ssr-icon/paths/information';
import classes from './OrganizationTable.module.scss';

const DepartmentsTable = () => {
    const { t } = useTranslation();
    const { isLoading } = useLoading();
    const { orgData } = useData();
    const { pathname } = useLocation();
    const [currentSearchParam, setCurrentSearchParam] = useState('');

    const filteredOrgData = useCallback(
        (dept: OrgDepartment) => dept.name.toLocaleLowerCase().includes(currentSearchParam.trim().toLocaleLowerCase())
            || dept.code.toLocaleLowerCase().includes(currentSearchParam.trim().toLocaleLowerCase()),
        [currentSearchParam]
    );

    return (
        <>
            <div className={classes['departments-table__header']}>
                <div id="om-departments-table-heading" data-testid="om-departments-table-heading">
                    <Text tagName="h4" headingSize="m">{t('DEPARTMENTS')}</Text>
                </div>
                <div id="om-departments-table-search" data-testid="om-departments-table-search">
                    <Search
                        id="searchbar"
                        data-testid="searchbar"
                        autoComplete="off"
                        onClear={() => { setCurrentSearchParam(''); }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, state: { scope: string | null, value: string | undefined }) => {
                            setCurrentSearchParam(state.value ?? '');
                        }}
                        onSearch={(
                            e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>,
                            data: { scope: string | null, value: string | undefined }
                        ) => {
                            setCurrentSearchParam(data.value ?? '');
                        }}
                        disabled={!pathname.includes('omTables')}
                        value={currentSearchParam}
                    />
                </div>
            </div>
            <div className={classes['departments-table__container']}>
                {!orgData ? (
                    <InlineMessage
                        id="om-departments-inline-message"
                        data-testid="om-departments-inline-message"
                        ssrIcon={information}
                        title={t('NO_DATA_MESSAGE')}
                    />
                )
                    : (
                        <>
                            <LoadingWrapper isLoading={isLoading} isLoadingIndicatorFixed>
                                <Table
                                    fullWidth
                                    className={classes['departments-table__table']}
                                    id="om-departments-table-table"
                                    data-testid="om-departments-table"
                                >
                                    <TableHeader sticky id="om-departments-table-header" data-testid="om-departments-table-header">
                                        <tr>
                                            <th>{t('CODE')}</th>
                                            <th>{t('NAME')}</th>
                                        </tr>
                                    </TableHeader>
                                    <TableBody id="om-departments-table-body" data-testid="om-departments-table-body">
                                        {orgData?.departments.filter(filteredOrgData).map(department => {
                                            const hasDeviation = department.deviation === true;
                                            if (!hasDeviation) {
                                                return (
                                                    <tr key={`${department.parentId}-${department.id}`}>
                                                        <td>{department.code}</td>
                                                        <td>{department.name}</td>
                                                    </tr>
                                                );
                                            }

                                            return null;
                                        })}
                                    </TableBody>
                                </Table>
                            </LoadingWrapper>
                            <div className={classes['departments-table__footer']} id="om-departments-table-total" data-testid="om-departments-table-total">
                                <Text tagName="h4" headingSize="s" id="om-departments-table-total-text" data-testid="om-departments-table-total-text">
                                    {`${t('TOTAL')} : ${orgData?.departments.filter(filteredOrgData).filter(x => x.deviation !== true).length}`}
                                </Text>
                            </div>
                        </>
                    )}
            </div>
        </>
    );
};

export default DepartmentsTable;
