import React, { Fragment, useMemo, useState } from 'react';
import { RecruitmentNeedsDelta } from 'types/recruitmentNeeds';
import InlineMessage from '@ingka/inline-message';
import useData from 'hooks/useData';
import { useTranslation } from 'react-i18next';
import information from '@ingka/ssr-icon/paths/information';
import useApp from 'hooks/useApp';
import Text from '@ingka/text';
import { isoWeekFromDateString, weekYearFromDateString } from 'utils/date';
import Button from '@ingka/button';
import calendar from '@ingka/ssr-icon/paths/calendar';
import Table, { TableHeader, TableBody } from '@ingka/table';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import arrowLeft from '@ingka/ssr-icon/paths/arrow-left-small';
import arrowRight from '@ingka/ssr-icon/paths/arrow-right-small';
import { useToast } from 'hooks/useToast';
import { useUpdateActionPlanCoworkerNote } from 'hooks/api/useUpdateActionPlanCoworkerNote';
import NewHiresRow from './NewHiresRow';
import classes from './NewHiresTable.module.scss';
import { getFilteredNewHires, getWeeklyHires } from './RecruitmentNeedsFunctions';

interface NewHiresProps {
    isLoading: boolean;
    recruitmentNeeds: RecruitmentNeedsDelta[] | null;
}

const NewHires: React.FC<NewHiresProps> = ({ isLoading, recruitmentNeeds }) => {
    const { t } = useTranslation();
    const { displayToast } = useToast();
    const { currentScenario } = useData();
    const { orgSelection: { selections: { costCentreSelection } }, timeSelection } = useApp();
    const { updateActionPlanCoworkerNote } = useUpdateActionPlanCoworkerNote();
    const startWeek = weekYearFromDateString(timeSelection.startDate);
    const endWeek = weekYearFromDateString(timeSelection.endDate);
    const [showPlanningPeriod, setShowPlanningPeriod] = useState<boolean>(true);
    const [weeklyOverviewWeekCount, setWeeklyOverviewWeekCount] = useState<number>(0);
    const [availableWeeksList, setAvailableWeeksList] = useState<string[] | null>(null);

    const newHiresData = useMemo(() => {
        setShowPlanningPeriod(true);
        setWeeklyOverviewWeekCount(0);
        if (recruitmentNeeds?.length) {
            const filteredNewHires = getFilteredNewHires(costCentreSelection, recruitmentNeeds, timeSelection);
            setAvailableWeeksList(getWeeklyHires(filteredNewHires ?? []).sort((a, b) => a.localeCompare(b)));

            return filteredNewHires;
        }

        return null;
    }, [recruitmentNeeds, costCentreSelection, timeSelection]);

    const weeklyNewHires = useMemo(() => {
        if (recruitmentNeeds?.length && availableWeeksList?.length && !showPlanningPeriod) {
            return (
                getFilteredNewHires(costCentreSelection, recruitmentNeeds, timeSelection)?.filter(
                    ({ startDate }) => isoWeekFromDateString(startDate) === availableWeeksList?.[weeklyOverviewWeekCount]
                ) ?? null
            );
        }

        return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableWeeksList, weeklyOverviewWeekCount, showPlanningPeriod]);

    const saveNote = async (personId: string, note: string) => {
        updateActionPlanCoworkerNote({ personId, note }).then(resp => {
            if (!resp.isResponseOk) {
                displayToast({ title: t('ERROR'), message: t('SAVE_FAILED') });

                return;
            }
            displayToast({ title: t('SUCCESS'), message: t('SAVE_SUCCEEDED') });
        });
    };

    const hasNewHireData: boolean = showPlanningPeriod ? !newHiresData?.length : !weeklyNewHires?.length;
    const newHiresHeader: string = `${t('PLANNED_RECRUITMENT_HEADER')}: `;
    const planningPeriodRange: string = `${t('WEEK')} ${startWeek} - ${t('WEEK')} ${endWeek}`;
    const availableWeek = availableWeeksList?.length ? availableWeeksList?.[weeklyOverviewWeekCount]?.split('-') : '';
    const weeklyRange: string = availableWeek?.length ? `${availableWeek?.[1]} - ${availableWeek?.[0]}` : `${t('WEEK')} ${startWeek}`;
    const tableData = showPlanningPeriod ? newHiresData ?? [] : weeklyNewHires ?? [];

    return (
        <>
            <div className={classes['new-hires__container']}>
                <div className={classes['new-hires__header']}>
                    <div>
                        <Text tagName="h5" headingSize="xs" id="new-hires-header" data-testid="new-hires-header">
                            {newHiresHeader}
                            {showPlanningPeriod || !currentScenario ? planningPeriodRange : weeklyRange}
                        </Text>
                    </div>
                    <div>
                        {!showPlanningPeriod && currentScenario && (
                            <>
                                <Button
                                    id="new-hires-week-left-btn"
                                    data-testid="new-hires-week-left-btn"
                                    className={classes['new-hires__week-switch-btn']}
                                    type="secondary"
                                    iconOnly
                                    size="xsmall"
                                    ssrIcon={arrowLeft}
                                    disabled={weeklyOverviewWeekCount === 0}
                                    onClick={() => setWeeklyOverviewWeekCount(prevCount => Math.max(prevCount - 1, 0))}
                                />
                                <Button
                                    id="new-hires-week-right-btn"
                                    data-testid="new-hires-week-right-btn"
                                    className={classes['new-hires__week-switch-btn']}
                                    type="secondary"
                                    iconOnly
                                    size="xsmall"
                                    ssrIcon={arrowRight}
                                    disabled={weeklyOverviewWeekCount === (availableWeeksList?.length ?? 0) - 1}
                                    onClick={() => setWeeklyOverviewWeekCount(prevCount => Math.min(prevCount + 1, (availableWeeksList?.length ?? 0) - 1))}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <Button
                        id="new-hires-table-switch-btn"
                        data-testid="new-hires-table-switch-btn"
                        size="small"
                        type="tertiary"
                        ssrIcon={calendar}
                        disabled={!currentScenario || hasNewHireData}
                        text={showPlanningPeriod ? t('SWITCH_WEEKLY_OVERVIEW') : t('SWITCH_PLANNING_PERIOD_OVERVIEW')}
                        onClick={() => setShowPlanningPeriod(!showPlanningPeriod)}
                    />
                </div>
            </div>

            {(!isLoading && hasNewHireData) || !currentScenario ? (
                <InlineMessage
                    id="new-hires-inline-message"
                    data-testid="new-hires-inline-message"
                    ssrIcon={information}
                    title={!currentScenario ? t('COWORKERS_SELECT_SCENARIO') : t('NO_DATA_MESSAGE')}
                />
            ) : (
                <div>
                    <LoadingWrapper isLoading={isLoading}>
                        <Table id="new-hires-table" data-testid="new-hires-table" fullWidth className={isLoading ? classes.table__loading : ''}>
                            <TableHeader id="new-hires-table-header" data-testid="new-hires-table-header">
                                <tr>
                                    <th className={classes.table__header}>
                                        <Text tagName="h5" headingSize="xs">{t('COST_CENTRE')}</Text>
                                    </th>
                                    <th className={classes.table__header}>
                                        <Text tagName="h5" headingSize="xs">{t('CONTRACT_DETAILS')}</Text>
                                    </th>
                                    <th className={classes.table__header}>
                                        <Text tagName="h5" headingSize="xs">{t('CONTRACT_PERIOD')}</Text>
                                    </th>
                                    <th className={classes['table__header-edit-button']}>
                                        <Text tagName="h5" headingSize="xs" />
                                    </th>
                                    <th className={classes.table__header}>
                                        <Text tagName="h5" headingSize="xs">{t('NOTES')}</Text>
                                    </th>
                                </tr>
                            </TableHeader>
                            <TableBody id="new-hires-table-body" data-testid="new-hires-table-body">
                                {tableData?.map(item => (
                                    <Fragment key={item.personId}>
                                        <NewHiresRow t={t} item={item} costCentreSelection={costCentreSelection} saveNoteCallBack={saveNote} />
                                    </Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </LoadingWrapper>
                </div>
            )}
        </>
    );
};

export default React.memo(NewHires);
