import Button from '@ingka/button';
import TextArea from '@ingka/text-area';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { NewHiresDelta } from 'types/recruitmentNeeds';
import { formatDate } from 'utils/date';
import { getShortCC } from 'utils/text';
import { CheckBox } from 'types/appContext';
import { getCCLabel } from './RecruitmentNeedsFunctions';
import classes from './NewHiresTable.module.scss';

interface NewHiresRowProps {
    t: TFunction<'translation', undefined>;
    item: NewHiresDelta,
    costCentreSelection: CheckBox[] | undefined;
    saveNoteCallBack: (personId: string, note: string) => void;
}

const NewHiresRow: React.FC<NewHiresRowProps> = ({ item, costCentreSelection, saveNoteCallBack, t }) => {
    const [showTextArea, setShowTextArea] = useState<boolean>(false);
    const [note, setNote] = useState<string | null>(null);

    return (
        <tr key={`${item.personId}-${item.costCentre}`}>
            <td data-testid="new-hires-row-costcenter" className={classes['new-hires-costCenter']}>
                {`${getShortCC(item.costCentre)} ${t(getCCLabel(item.costCentre, costCentreSelection)).toLowerCase()}`}
            </td>
            <td data-testid="new-hires-row-contractType">
                {`${t(item.contractType)}, ${t(item.employmentType)}, ${item.contractHours} ${t('HRS_PER_WEEK')}`}
            </td>
            <td data-testid="new-hires-row-dates">{`${formatDate(item.startDate)} - ${formatDate(item.endDate)}`}</td>
            <td>
                {showTextArea
                    ? (
                        <Button
                            id="new-hires-table-edit-btn"
                            data-testid="new-hires-table-edit-btn"
                            size="small"
                            type="secondary"
                            text={t('Save')}
                            onClick={() => { saveNoteCallBack(item.personId, note ?? item.notes); setShowTextArea(false); }}
                        />
                    ) : (
                        <Button
                            id="new-hires-table-save-btn"
                            data-testid="new-hires-table-save-btn"
                            size="small"
                            type="secondary"
                            text={t('Edit')}
                            onClick={() => setShowTextArea(true)}
                        />
                    )}
            </td>
            <td data-testid="new-hires-row-textArea">
                {showTextArea
                    ? (
                        <TextArea
                            data-testid="newHire-changes-note"
                            label=""
                            maxLength={4096}
                            defaultValue={note ?? item.notes}
                            onBlur={event => { setNote(event.target.value); }}
                        />
                    ) : (note ?? item.notes)}
            </td>
        </tr>
    );
};

export default NewHiresRow;
