import useLoading from 'hooks/useLoading';
import React, { useState, useCallback } from 'react';
import useData from 'hooks/useData';
import Table, { TableBody, TableHeader } from '@ingka/table';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Search from '@ingka/search';
import InlineMessage from '@ingka/inline-message';
import information from '@ingka/ssr-icon/paths/information';
import classes from './OrganizationTable.module.scss';

type BusinessUnitType = {
    code: string;
    companyCode: string;
    companyName: string;
    country: string;
    leaderId: string;
    name: string;
    type: string;
};
const BusinessUnitsTable = () => {
    const { t } = useTranslation();
    const { isLoading } = useLoading();
    const { orgData } = useData();
    const { pathname } = useLocation();
    const [currentSearchParam, setCurrentSearchParam] = useState('');

    const filteredOrgData = useCallback(
        (businessUnit: BusinessUnitType) => businessUnit.name.toLocaleLowerCase().includes(currentSearchParam.trim().toLocaleLowerCase()),
        [currentSearchParam]
    );

    return (
        <>
            <div id="om-business-units-table" data-testid="om-business-units-table" className={classes['business-units-table__header']}>
                <div id="om-business-units-table-heading" data-testid="om-business-units-table-heading">
                    <Text tagName="h4" headingSize="m">{t('BUSINESS_UNITS')}</Text>
                </div>
                <div id="om-business-units-table-search" data-testid="om-business-units-table-search">
                    <Search
                        id="searchbar"
                        data-testid="searchbar"
                        autoComplete="off"
                        onClear={() => { setCurrentSearchParam(''); }}
                        onChange={(_, state: { scope: string | null, value: string | undefined }) => {
                            setCurrentSearchParam(state.value ?? '');
                        }}
                        onSearch={(
                            _,
                            data: { scope: string | null, value: string | undefined }
                        ) => {
                            setCurrentSearchParam(data.value ?? '');
                        }}
                        disabled={!pathname.includes('omTables')}
                        value={currentSearchParam}
                    />
                </div>
            </div>
            <div className={classes['business-units-table__container']}>
                {!orgData ? (
                    <InlineMessage
                        id="om-business-units-inline-message"
                        data-testid="om-business-units-inline-message"
                        ssrIcon={information}
                        title={t('NO_DATA_MESSAGE')}
                    />
                ) : (
                    <>
                        <LoadingWrapper isLoading={isLoading} isLoadingIndicatorFixed>
                            <Table
                                fullWidth
                                className={classes['business-units-table__table']}
                                id="om-business-units-table-container"
                                data-testid="om-business-units-table-container"
                            >
                                <TableHeader sticky id="om-business-units-table-header" data-testid="om-business-units-table-header">
                                    <tr>
                                        <th>{t('CODE')}</th>
                                        <th>{t('COMPANY_CODE')}</th>
                                        <th>{t('COMPANY_NAME')}</th>
                                        <th>{t('type')}</th>
                                        <th>{t('LEADER_ID')}</th>
                                        <th>{t('NAME')}</th>
                                    </tr>
                                </TableHeader>
                                <TableBody id="om-business-units-table-body" data-testid="om-business-units-table-body">
                                    {(orgData?.businessUnits as unknown as BusinessUnitType[]).filter(filteredOrgData).map(businessUnit => (
                                        <tr
                                            key={businessUnit.code}
                                            id={`om-business-units-table-row-${businessUnit.code}`}
                                            data-testid={`om-business-units-table-row-${businessUnit.code}`}
                                        >
                                            <td>{businessUnit.code}</td>
                                            <td>{businessUnit.companyCode}</td>
                                            <td>{businessUnit.companyName}</td>
                                            <td>{businessUnit.type}</td>
                                            <td>{businessUnit.leaderId}</td>
                                            <td>{businessUnit.name}</td>
                                        </tr>
                                    ))}
                                </TableBody>
                            </Table>
                        </LoadingWrapper>
                        <div className={classes['business-units-table__footer']} id="om-business-units-table-total" data-testid="om-business-units-table-total">
                            <Text tagName="h4" headingSize="s" id="om-business-units-table-total-text" data-testid="om-business-units-table-total-text">
                                {`${t('TOTAL')} : ${(orgData?.businessUnits as unknown as BusinessUnitType[]).filter(filteredOrgData).length}`}
                            </Text>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default BusinessUnitsTable;
