import Table, { TableHeader, TableBody } from '@ingka/table';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import useData from 'hooks/useData';
import useLoading from 'hooks/useLoading';
import React, { useCallback, useState } from 'react';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { OrgDivision } from 'types/dataContext';
import Search from '@ingka/search';
import InlineMessage from '@ingka/inline-message';
import information from '@ingka/ssr-icon/paths/information';
import classes from './OrganizationTable.module.scss';

const DivisionsTable = () => {
    const { t } = useTranslation();
    const { isLoading } = useLoading();
    const { orgData } = useData();
    const { pathname } = useLocation();
    const [currentSearchParam, setCurrentSearchParam] = useState('');

    const filteredOrgData = useCallback(
        (division: OrgDivision) => division.name.toLocaleLowerCase().includes(currentSearchParam.trim().toLocaleLowerCase())
                    || division.code.toLocaleLowerCase().includes(currentSearchParam.trim().toLocaleLowerCase()),
        [currentSearchParam]
    );

    return (
        <>
            <div className={classes['divisions-table__header']}>
                <div id="om-divisions-table-heading" data-testid="om-divisions-table-heading">
                    <Text tagName="h4" headingSize="m">{t('DIVISION')}</Text>
                </div>
                <div id="om-divisions-table-search" data-testid="om-divisions-table-search">
                    <Search
                        id="searchbar"
                        data-testid="searchbar"
                        autoComplete="off"
                        onClear={() => { setCurrentSearchParam(''); }}
                        onChange={(e, state: { scope: string | null, value: string | undefined }) => {
                            setCurrentSearchParam(state.value ?? '');
                        }}
                        onSearch={(e, data: { scope: string | null, value: string | undefined }) => {
                            setCurrentSearchParam(data.value ?? '');
                        }}
                        disabled={!pathname.includes('omTables')}
                        value={currentSearchParam}
                    />
                </div>
            </div>
            <div className={classes['divisions-table__container']}>
                {!orgData ? (
                    <InlineMessage
                        id="om-divisions-table-inline-message"
                        data-testid="om-divisions-table-inline-message"
                        ssrIcon={information}
                        title={t('NO_DATA_MESSAGE')}
                    />
                )
                    : (
                        <>
                            <LoadingWrapper isLoading={isLoading} isLoadingIndicatorFixed>
                                <Table fullWidth className={classes['divisions-table__table']} id="om-divisions-table-table" data-testid="om-divisions-table">
                                    <TableHeader sticky id="om-divisions-table-header" data-testid="om-divisions-table-header">
                                        <tr>
                                            <th>{t('CODE')}</th>
                                            <th>{t('NAME')}</th>
                                        </tr>
                                    </TableHeader>
                                    <TableBody id="om-divisions-table-body" data-testid="om-divisions-table-body">
                                        {orgData?.divisions.filter(filteredOrgData).map(divisions => {
                                            const hasDeviation = divisions.deviation === true;
                                            if (!hasDeviation) {
                                                return (
                                                    <tr key={divisions.id}>
                                                        <td>{divisions.code}</td>
                                                        <td>{divisions.name}</td>
                                                    </tr>
                                                );
                                            }

                                            return null;
                                        })}
                                    </TableBody>
                                </Table>
                            </LoadingWrapper>
                            <div className={classes['divisions-table__footer']} id="om-divisions-table-total" data-testid="om-divisions-table-total">
                                <Text tagName="h4" headingSize="s" id="om-divisions-table-total-text" data-testid="om-divisions-table-total-text">
                                    {`${t('TOTAL')} : ${orgData?.divisions.filter(filteredOrgData).filter(x => x.deviation !== true).length}`}
                                </Text>
                            </div>
                        </>
                    )}
            </div>
        </>
    );
};

export default DivisionsTable;
