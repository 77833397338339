import { PageContainer } from 'components';
import { OM_TABLES } from 'types/routes';
import OrganizationManagementTables from 'views/OMTables/OMTables';

const OMTablesRoute = () => (
    <PageContainer pageAnalyticsId={OM_TABLES} isFullWidth align="horizontal">
        <OrganizationManagementTables />
    </PageContainer>
);

export default OMTablesRoute;
