import React, { useState, useCallback } from 'react';
import Table, { TableHeader, TableBody } from '@ingka/table';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import useData from 'hooks/useData';
import useLoading from 'hooks/useLoading';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import Search from '@ingka/search';
import { OrgCostCentre } from 'types/dataContext';
import { useLocation } from 'react-router-dom';
import InlineMessage from '@ingka/inline-message';
import information from '@ingka/ssr-icon/paths/information';
import classes from './OrganizationTable.module.scss';

const CostCentresTable = () => {
    const { t } = useTranslation();
    const { orgData } = useData();
    const { isLoading } = useLoading();
    const { pathname } = useLocation();
    const [currentSearchParam, setCurrentSearchParam] = useState('');

    const filteredOrgData = useCallback(
        (costCentre: OrgCostCentre) => costCentre.desc.toLocaleLowerCase().includes(currentSearchParam.trim().toLocaleLowerCase())
            || costCentre.code.toLocaleLowerCase().includes(currentSearchParam.trim().toLocaleLowerCase()),
        [currentSearchParam]
    );

    return (
        <>
            <div className={classes['cost-centres-table__header']}>
                <div id="om-cost-centres-table-heading" data-testid="om-cost-centres-table-heading">
                    <Text tagName="h4" headingSize="m">{t('COST_CENTERS') }</Text>
                </div>
                <div id="om-cost-centres-table-search" data-testid="om-cost-centres-table-search">
                    <Search
                        id="searchbar"
                        data-testid="searchbar"
                        autoComplete="off"
                        onClear={() => { setCurrentSearchParam(''); }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, state: { scope: string | null, value: string | undefined }) => {
                            setCurrentSearchParam(state.value ?? '');
                        }}
                        onSearch={(
                            e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>,
                            data: { scope: string | null, value: string | undefined }
                        ) => {
                            setCurrentSearchParam(data.value ?? '');
                        }}
                        disabled={!pathname.includes('omTables')}
                        value={currentSearchParam}
                    />
                </div>
            </div>
            <div className={classes['cost-centres-table__container']}>
                {!orgData ? (
                    <InlineMessage
                        id="om-cost-centres-table-inline-message"
                        data-testid="om-cost-centres-table-inline-message"
                        ssrIcon={information}
                        title={t('NO_DATA_MESSAGE')}
                    />
                ) : (
                    <>
                        <LoadingWrapper isLoading={isLoading} isLoadingIndicatorFixed>
                            <Table
                                fullWidth
                                className={classes['cost-centres-table__table']}
                                id="om-cost-centres-table-table"
                                data-testid="om-cost-centres-table"
                            >
                                <TableHeader sticky id="om-cost-centres-table-header" data-testid="om-cost-centres-table-header">
                                    <tr>
                                        <th>{t('CODE')}</th>
                                        <th>{t('NAME')}</th>
                                    </tr>
                                </TableHeader>
                                <TableBody id="om-cost-centres-table-body" data-testid="om-cost-centres-table-body">
                                    {orgData?.costCentres.filter(filteredOrgData).map(costCentre => {
                                        const hasDeviation = costCentre.deviation === true;
                                        if (!hasDeviation) {
                                            return (
                                                <tr key={costCentre.id}>
                                                    <td>{costCentre.code}</td>
                                                    <td>{costCentre.desc}</td>
                                                </tr>
                                            );
                                        }

                                        return null;
                                    })}
                                </TableBody>
                            </Table>
                        </LoadingWrapper>
                        <div className={classes['cost-centres-table__footer']} id="om-cost-centres-table-total" data-testid="om-cost-centres-table-total">
                            <Text tagName="h4" headingSize="s" id="om-cost-centres-table-total-text" data-testid="om-cost-centres-table-total-text">
                                {`${t('TOTAL')} : ${orgData?.costCentres.filter(filteredOrgData).filter(x => x.deviation !== true).length}`}
                            </Text>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CostCentresTable;
