import { PageContainer } from 'components';
import React from 'react';
import BusinessUnitsTable from 'components/OrganizationTables/BusinessUnitsTable';
import CostCentresTable from 'components/OrganizationTables/CostCentresTable';
import DepartmentsTable from 'components/OrganizationTables/DepartmentsTable';
import DivisionsTable from 'components/OrganizationTables/DivisionsTable';
import classes from './OMTables.module.scss';

const OrganizationManagementTables = () => (
    <PageContainer testid="om-tables-page" className={classes['om-table__container']}>
        <>
            <BusinessUnitsTable />
            <CostCentresTable />
            <DepartmentsTable />
            <DivisionsTable />
        </>

    </PageContainer>
);
export default OrganizationManagementTables;
