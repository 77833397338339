import useAuth from 'hooks/useAuth';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BlankPage from 'routes/BlankPage';
import KapplaNavigate from 'routes/KapplaNavigate';
import { NavItemsWithData, getAllNavLinks, getOMTableNavLink } from 'routes/appRoutes';
import { UserAccessRoutes } from 'types/authContext';
import { ROUTE_UNAUTHORIZED } from 'types/routes';

/**
 * Controls access to Routes based on user's access. If user has access to the route, it returns that page.
 * If user doesn't have access to the route, it redirects to the first route that the user has access to.
 * If user doesn't have access to any route, or there is an error in the auth process, it redirects to the unauthorized route.
 */
export const ProtectedRoute = ({ path, children }: { path: keyof UserAccessRoutes, children: ReactNode }): ReactNode => {
    const { access, user, error, isLoading } = useAuth();

    // Redirect to unauthorized route if there is any error in the authorization/authentication process.
    if (error) {
        return <KapplaNavigate to={ROUTE_UNAUTHORIZED} />;
    }

    // Return empty div while waiting for apis to complete and user/access to be loaded
    if (isLoading || !user || !access) {
        return <BlankPage />;
    }

    // If user has access to the route, return the element.
    if (access?.routes?.[path]) {
        return children;
    }

    // Redirect to another route if user has access to some routes but not the current one.
    // Note: should only happen on first load, if the default redirect is not available to user.
    const firstAccessRoute = Object.entries(access?.routes ?? {}).find(routeAccess => routeAccess[1]);
    if (firstAccessRoute) {
        return <KapplaNavigate to={firstAccessRoute[0]} />;
    }

    // This is reached when user doesn't have access to any route in the application.
    return <KapplaNavigate to={ROUTE_UNAUTHORIZED} />;
};

/**
 * Returns all the navigational links for the app bar that the user has access to
 */
export const useProtectedLinks = (): NavItemsWithData => {
    const { access, user, error } = useAuth();
    const location = useLocation();
    const { t } = useTranslation();
    if (!user || !!error || !access?.routes) {
        return [];
    }

    const showOMTablesPage = (process.env.REACT_APP_ENV !== 'prod' && user.role === 'OPP Developer');

    return showOMTablesPage ? getOMTableNavLink(location.pathname.replaceAll('/', ''), t).filter(link => access?.routes?.[link.href])
        : getAllNavLinks(location.pathname.replaceAll('/', ''), t).filter(link => access?.routes?.[link.href]);
};
