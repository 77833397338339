import { UserAccessRoutes, UserPermission, UserProfile } from 'types/authContext';

export const noUserRoutesAccess: UserAccessRoutes = {
    capacityOverview: false,
    manageCapacity: false,
    capacityActionPlan: false,
    recruitmentNeeds: false,
    configuration: false,
    omTables: false,
};

const hasCapacityOverviewAccess = (userProfile: UserProfile | null) => !!userProfile?.rolePermissions.includes(UserPermission.VIEW_CAPACITY_OVERVIEW);

const hasManageCapacityAccess = (userProfile: UserProfile | null) => (!!userProfile?.rolePermissions.includes(UserPermission.VIEW_MANAGE_CAPACITY));

const hasActionPlanAccess = (userProfile: UserProfile | null) => (!!userProfile?.rolePermissions.includes(UserPermission.VIEW_ACTION_PLAN));

const hasRecruitmentNeedsAccess = (userProfile: UserProfile | null) => (!!userProfile?.rolePermissions.includes(UserPermission.VIEW_RECRUITMENT_NEEDS));

const hasConfigurationPageAccess = (userProfile: UserProfile | null) => (!!userProfile?.rolePermissions.includes(UserPermission.VIEW_CONFIGURATION));

/**
 * Om Tables page can only be accessed by ADMIN, as it is not a part of the user permissions
 * kappla-admin is a role that is only given to the admin user which is now "OPP Developer".
 * To pass the test, added kappla-admin check to the condition.
 */
const hasOMTablesPageAccess = (userProfile: UserProfile | null) => (
    !!userProfile?.role.includes('OPP Developer') || !!userProfile?.role.includes('kappla-admin')
) && process.env.REACT_APP_ENV !== 'prod';

/**
 * Handles whether the user, given the current environment and role, has access to each route on the site
 */
export const getAccessRoutes = (userProfile: UserProfile | null): UserAccessRoutes => {
    if (!userProfile) return noUserRoutesAccess;

    return {
        capacityOverview: hasCapacityOverviewAccess(userProfile),
        manageCapacity: hasManageCapacityAccess(userProfile),
        capacityActionPlan: hasActionPlanAccess(userProfile),
        recruitmentNeeds: hasRecruitmentNeedsAccess(userProfile),
        configuration: hasConfigurationPageAccess(userProfile),
        omTables: hasOMTablesPageAccess(userProfile),

    };
};
