import { ReactNode } from 'react';
import { CAPACITY_OVERVIEW_LINK__CLICK,
    MANAGE_CAPACITY_LINK__CLICK,
    ACTION_PLAN_LINK__CLICK,
    RECRUITMENT_NEEDS_LINK__CLICK } from 'types/analytics';
import { UserAccessRoutes } from 'types/authContext';
import { ROUTE_CAPACITY_OVERVIEW,
    ROUTE_MANAGE_CAPACITY,
    ROUTE_CAPACITY_ACTION_PLAN,
    ROUTE_RECRUITMENT_NEEDS,
    ROUTE_CONFIGURATION,
    OM_TABLES } from 'types/routes';
import { Header as CWDSHeader } from '@ingka-group-digital/cwds-react-header';
import { TFunction } from 'i18next';
import RecruitmentNeedsRoute from './RecruitmentNeedsRoute';
import CapacityActionPlanRoute from './capacityActionPlanRoute';
import CapacityOverviewRoute from './capacityOverviewRoute';
import ManageCapacityRoute from './manageCapacityRoute';
import ConfigurationRoute from './configurationRoute';
import OMTablesRoute from './OMTablesRoute';

export type AppRoute = {
    path: keyof UserAccessRoutes;
    element: ReactNode;
};
export const appRoutes: AppRoute[] = [
    { path: ROUTE_CAPACITY_OVERVIEW, element: <CapacityOverviewRoute /> },
    { path: ROUTE_MANAGE_CAPACITY, element: <ManageCapacityRoute /> },
    { path: ROUTE_CAPACITY_ACTION_PLAN, element: <CapacityActionPlanRoute /> },
    { path: ROUTE_RECRUITMENT_NEEDS, element: <RecruitmentNeedsRoute /> },
    { path: ROUTE_CONFIGURATION, element: <ConfigurationRoute /> },
    { path: OM_TABLES, element: <OMTablesRoute /> },
];

export type NavItemsWithData = Array<
NonNullable<React.ComponentProps<typeof CWDSHeader>['navItems']>[number] & { testid: string; analyticsId: string, href: keyof UserAccessRoutes }
>;

/**
 * All pages in the app except Config (which does not have a traditional navlink in the app bar)
 */
export const getAllNavLinks = (
    pagePath: string,
    t: TFunction<'translation', undefined, 'translation'>,
): NavItemsWithData => [{
    href: ROUTE_CAPACITY_OVERVIEW,
    label: t('OVERVIEW'),
    isActive: pagePath === ROUTE_CAPACITY_OVERVIEW,
    analyticsId: CAPACITY_OVERVIEW_LINK__CLICK,
    testid: 'capacity-overview-link',
}, {
    href: ROUTE_MANAGE_CAPACITY,
    label: t('MANAGE'),
    isActive: pagePath === ROUTE_MANAGE_CAPACITY,
    analyticsId: MANAGE_CAPACITY_LINK__CLICK,
    testid: 'manage-capacity-link',
}, {
    href: ROUTE_CAPACITY_ACTION_PLAN,
    label: t('ACTION_PLAN'),
    isActive: pagePath === ROUTE_CAPACITY_ACTION_PLAN,
    analyticsId: ACTION_PLAN_LINK__CLICK,
    testid: 'action-plan-link',
}, {
    href: ROUTE_RECRUITMENT_NEEDS,
    label: t('RECRUITMENT_NEEDS'),
    isActive: pagePath === ROUTE_RECRUITMENT_NEEDS,
    analyticsId: RECRUITMENT_NEEDS_LINK__CLICK,
    testid: 'recruitment-needs-link',
}];

export const getOMTableNavLink = (
    pagePath: string,
    t: TFunction<'translation', undefined, 'translation'>,
): NavItemsWithData => getAllNavLinks(pagePath, t).concat([{
    href: OM_TABLES,
    label: t('OM_TABLES'),
    isActive: pagePath === OM_TABLES,
    analyticsId: '',
    testid: 'om-tables-link',
}]);
